export const backgroundColor = '#0A0C1B'
export const backgroundColor2 = '#161729'
export const shadowColor = '#00000040'
export const borderColor = '#272B40'
export const textColor = '#FFF'
export const subTextColor = '#989FBA'
export const backgroundTextColor = '#3F4468'
export const bgTextColor = '#FFF'
export const placeholderColor = '#9090A0'
export const primaryColor = '#42edf8'
export const secondaryColor = '#00E205'
export const tertiaryColor = '#9013FE'
export const upColor = '#00E205'
export const downColor = '#F90021'
export const headerColor = '#10122399'
export const inactiveColor = '#242649'
export const counterColor = '#6D728F'